export const environment = {
  production: false,
  endPoint: 'https://careecoapiqa.careeco.net/careeco/',
  careOutreachBaseUrl: 'https://cecareoutreachapiqa.careeco.net/careoutreach/',
  externalGateway: 'https://ceexternalgatewayapiqa.careeco.net/externalgw/',
  visorBaseUrl: 'https://cevisorapiqa.careeco.net/visor/',
  // endPoint: 'https://careecoapiqa.herokuapp.com/careeco/',
  userWebUrl: 'https://qame.careeco.net',
  providerConnetUrl: 'https://connectqa.careeco.net/',
  windowName: 'ProviderConnectQa',
  agmApiKey: "AIzaSyDiA6GCvPSRSLVCQkZ7YX54TZqW5IgzvIo"
};